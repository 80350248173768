import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import {
  styled,
  TablePagination,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Typography,
} from '@mui/material';
import { useState, useEffect } from 'react';
import PageHeader from 'app/PageHeader';
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone';
import axios from 'axios';
import { Paths } from '../../../../app/baseApi/baseApi';
import toast from 'react-hot-toast';
import * as XLSX from 'xlsx';
import { format } from 'date-fns';

const Container = styled('div')(({ theme }) => ({
  margin: '50px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '50px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
  },
}));

const CapturedPayments = () => {
  const [payments, setPayments] = useState({ successful: [], failed: [], pending: [], others: [] });
  const [selectedPlan, setSelectedPlan] = useState('');
  const [plans, setPlans] = useState([]);
  const [filter, setFilter] = useState('all');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);

  const showAllPlan = async () => {
    try {
      setLoading(true);
      const endpoints = [
        { url: Paths.EndpointsURL.MLBPROPriceTab, name: 'MLBPROPrice' },
        { url: Paths.EndpointsURL.PlanCPlusTabPrice, name: 'CPlusPrice' },
        { url: Paths.EndpointsURL.PlanUGTabPrice, name: 'UGTabPrice' },
        { url: Paths.EndpointsURL.PlanATabPrice, name: 'ATabPrice' },
        { url: Paths.EndpointsURL.PlanMScTabPrice, name: 'MScTabPrice' },
        { url: Paths.EndpointsURL.PlanTHTabPrice, name: 'THTabPrice' },
        { url: Paths.EndpointsURL.MLBLiteTabPrice, name: 'TANDPrice' },
        { url: Paths.EndpointsURL.GetPlanQBPriceTab, name: 'QBTabPrice' },
        { url: Paths.EndpointsURL.GetMasterStrokePriceTab, name: 'NorcetTabPrice' },
      ];
      const responses = await Promise.all(
        endpoints.map((endpoint) =>
          axios.get(endpoint.url, {
            headers: { 'Content-type': 'application/json' },
          })
        )
      );
      const allPlans = responses.flatMap((response) => {
        const data = response.data.data;
        return Array.isArray(data) ? data : data.records || [];
      });
      const uniquePlans = Array.from(
        new Map(
          allPlans.map((plan) => {
            let planName = plan.planName.trim();
            if (planName === 'TH') planName = 'TH ';
            return [planName, { ...plan, planName }];
          })
        ).values()
      );
      setPlans(uniquePlans);
      if (uniquePlans.length > 0 && !selectedPlan) {
        setSelectedPlan(uniquePlans[0].planName);
      }
    } catch (error) {
      setPlans([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchPayments = async (planName, dateFilter = filter) => {
    try {
      setLoading(true);
      const adjustedPlanName = planName === 'Plan TH' ? 'Plan TH ' : planName;
      let params = {
        planName: adjustedPlanName,
        page: page + 1,
        limit: rowsPerPage,
        filter: dateFilter,
      };
      const response = await axios.get(Paths.EndpointsURL.RazorpayCaptured, { params });
      if (response.data.success) {
        if (
          !response.data.data.payments ||
          Object.values(response.data.data.payments).every((arr) => arr.length === 0)
        ) {
          toast.info('No payments received for this plan', { position: 'top-right' });
        }
        setPayments(response.data.data.payments);
        setTotalRecords(response.data.data.pagination.totalRecords);
      }
    } catch (error) {
      setPayments({ successful: [], failed: [], pending: [], others: [] });
      setTotalRecords(0);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    showAllPlan();
  }, []);

  useEffect(() => {
    if (selectedPlan) {
      fetchPayments(selectedPlan);
    }
  }, [selectedPlan, page, rowsPerPage, filter]);

  const handlePlanChange = (event) => {
    setSelectedPlan(event.target.value);
    setPage(0);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    setPage(0);
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getAllPayments = () => {
    const allPayments = [
      ...payments.successful,
      ...payments.failed,
      ...payments.pending,
      ...payments.others,
    ];

    if (filter === 'successful') return allPayments.filter((p) => p.paymentStatus === 'captured');
    if (filter === 'failed') return allPayments.filter((p) => p.paymentStatus === 'failed');
    if (filter === 'pending') return allPayments.filter((p) => p.paymentStatus === 'pending');
    return allPayments;
  };

  const downloadExcel = () => {
    const allPayments = getAllPayments();
    const dataForExcel = allPayments.map((payment) => ({
      Name: payment.Notes?.name,
      Email: payment.Notes?.email,
      Phone: payment.Notes?.number,
      'Order Status': payment.orderStatus,
      'Payment Status': payment.paymentStatus,
      'Paid Amount': payment.paidAmount,
      Currency: payment.currency,
      'Payment Method': payment.payment.method,
      'Plan Name': payment.payment.planName,
      'Plan Validity': payment.payment.planValidity,
    }));
    const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Payments');
    XLSX.writeFile(workbook, `RazorpayPayments_${format(new Date(), 'dd-MM-yyyy')}.xlsx`);
  };

  return (
    <Container>
      <PageHeader
        title="Captured Payment Details"
        subTitle="Razorpay Payment Status"
        icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
      />
      <div style={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
        <FormControl variant="outlined" style={{ minWidth: 200 }}>
          <InputLabel>Select Plan</InputLabel>
          <Select value={selectedPlan} onChange={handlePlanChange} label="Select Plan">
            {plans.map((plan) => (
              <MenuItem key={plan.planName} value={plan.planName}>
                {plan.planName.replace(/\s+$/, '')}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="outlined" style={{ minWidth: 200 }}>
          <InputLabel>Filter</InputLabel>
          <Select value={filter} onChange={handleFilterChange} label="Filter">
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="successful">Paid</MenuItem>
            <MenuItem value="failed">Failed</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
          </Select>
        </FormControl>

        <Button onClick={downloadExcel} variant="contained" color="primary" disabled={loading}>
          Download Excel
        </Button>
      </div>

      <MDBTable align="middle">
        <MDBTableHead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Phone</th>
            <th scope="col">Plan Name</th>
            <th scope="col">Validity</th>
            <th scope="col">Paid Amount</th>
            <th scope="col">Payment Method</th>
            <th scope="col">Status</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {loading ? (
            <tr>
              <td colSpan="8">
                <Typography variant="h6" align="center">
                  Loading...
                </Typography>
              </td>
            </tr>
          ) : getAllPayments().length === 0 ? (
            <tr>
              <td colSpan="8">
                <Typography variant="h6" align="center">
                  No payments received
                </Typography>
              </td>
            </tr>
          ) : (
            getAllPayments().map((payment, index) => (
              <tr key={index}>
                <td>
                  <p className="fw-normal mb-1">{payment.Notes?.name || '-'}</p>
                </td>
                <td>
                  <p className="fw-normal mb-1">{payment.Notes?.email || '-'}</p>
                </td>
                <td>
                  <p className="fw-normal mb-1">{payment.Notes?.number || '-'}</p>
                </td>
                <td>
                  <p className="fw-normal mb-1">{payment.payment.planName}</p>
                </td>
                <td>
                  <p className="fw-normal mb-1">{payment.payment.planValidity}</p>
                </td>
                <td>
                  <p className="fw-normal mb-1">{`${payment.currency} ${payment.paidAmount}`}</p>
                </td>
                <td>
                  <p className="fw-normal mb-1">{payment.payment.method || '-'}</p>
                </td>
                <td>
                  <p className="fw-normal mb-1">{payment.paymentStatus}</p>
                </td>
              </tr>
            ))
          )}
        </MDBTableBody>
      </MDBTable>

      <TablePagination
        sx={{ px: 2 }}
        page={page}
        component="div"
        rowsPerPage={rowsPerPage}
        count={totalRecords}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[10, 20, 30]}
        onRowsPerPageChange={handleChangeRowsPerPage}
        nextIconButtonProps={{ 'aria-label': 'Next Page' }}
        backIconButtonProps={{ 'aria-label': 'Previous Page' }}
      />
    </Container>
  );
};

export default CapturedPayments;
