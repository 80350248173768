import Modal from 'react-bootstrap/Modal';
import UploadPlanNorcetPrice from './UploadPlanNorcetPrice';
const UploadNorcetPriceModel = (props) => {
  return (
    <>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton></Modal.Header>
        <UploadPlanNorcetPrice />
      </Modal>
    </>
  );
};

export default UploadNorcetPriceModel;
