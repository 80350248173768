import React from 'react';
import Modal from 'react-bootstrap/Modal';
import toast from 'react-hot-toast';
import { Paths } from '../../../baseApi/baseApi';
import axios from 'axios';
import EditOfferTab from './EditOffersTab';

const EditOffersTabModel = ({ editPlanQBBenefitsData, show, onHide }) => {
  const handleEditSubmit = async (editedData) => {
    try {
      const formData = new FormData();
      formData.append('validity', JSON.stringify(editedData.validity));
      if (editedData.bannerImage) {
        formData.append('bannerImage', editedData.bannerImage);
      }
      const response = await axios.put(
        `${Paths.EndpointsURL.GetOffersTabPrice}/${editedData._id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      toast.success(response.data.message, { position: 'top-right' });
      window.location.reload();
      onHide();
    } catch (error) {
      console.error('Error updating data:', error);
      toast.error(`Error updating: ${error.response?.data?.message || error.message}`, {
        position: 'top-right',
      });
    }
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <EditOfferTab editPlanQBBenefitsData={editPlanQBBenefitsData} onEdit={handleEditSubmit} />
      </Modal.Body>
    </Modal>
  );
};

export default EditOffersTabModel;
