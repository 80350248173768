import React, { useState, useEffect } from 'react';
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBFile,
  MDBInput,
  MDBRow,
} from 'mdb-react-ui-kit';
import ReactQuill from 'react-quill';

export default function EditOfferTab({ editPlanQBBenefitsData, onEdit }) {
  const [formData, setFormData] = useState({
    ...editPlanQBBenefitsData,
    validity: editPlanQBBenefitsData?.validity || [
      {
        plan: '',
        month: '',
        price: '',
        features: [],
      },
    ],
  });
  const [isError, setIsError] = useState({});

  useEffect(() => {
    if (editPlanQBBenefitsData) {
      setFormData({
        ...editPlanQBBenefitsData,
        validity: editPlanQBBenefitsData.validity || [
          {
            plan: '',
            month: '',
            price: '',
            features: [],
          },
        ],
      });
    }
  }, [editPlanQBBenefitsData]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData((prev) => ({
      ...prev,
      bannerImage: file,
    }));
    setIsError((prev) => ({
      ...prev,
      bannerImage: '',
    }));
  };

  const handleValidityChange = (index, e) => {
    const { name, value } = e.target;
    const newValidity = [...formData.validity];
    newValidity[index] = { ...newValidity[index], [name]: value };
    setFormData({ ...formData, validity: newValidity });
    setIsError({ ...isError, [name]: '' });
  };

  const handleFeaturesChange = (index, value) => {
    const newValidity = [...formData.validity];
    newValidity[index].features = value.split('\n').filter((feature) => feature.trim() !== '');
    setFormData({ ...formData, validity: newValidity });
  };

  const addValidityPlan = () => {
    setFormData({
      ...formData,
      validity: [...formData.validity, { plan: '', month: '', price: '', features: [] }],
    });
  };

  const removeValidityPlan = (index) => {
    if (formData.validity.length > 1) {
      const newValidity = formData.validity.filter((_, i) => i !== index);
      setFormData({ ...formData, validity: newValidity });
    }
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};
    formData.validity.forEach((plan, index) => {
      if (!plan.plan.trim()) {
        // newErrors[`plan_${index}`] = 'Plan Name is required';
        isValid = false;
      }
    });
    setIsError(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      onEdit(formData);
    }
  };

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ header: 1 }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ direction: 'rtl' }],
    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ['clean'],
  ];

  const modules = {
    toolbar: toolbarOptions,
  };

  return (
    <MDBContainer fluid>
      <MDBRow className="d-flex justify-content-center align-items-center">
        <MDBCol lg="12" className="my-2">
          <h3 className="text-black mt-0 text-center">Edit Offer Tab</h3>
          <form onSubmit={handleSubmit}>
            <MDBCard>
              <MDBCardBody className="px-4">
                <MDBRow className="align-items-center pt-4 pb-3">
                  <MDBCol md="12">
                    <MDBFile
                      size="lg"
                      id="bannerImage"
                      name="bannerImage"
                      accept="image/*"
                      onChange={handleImageChange}
                    />
                  </MDBCol>
                </MDBRow>

                {formData.validity.map((plan, index) => (
                  <div key={index}>
                    <MDBRow className="align-items-center pt-4 pb-3">
                      <MDBCol md="4">
                        <MDBInput
                          label="Plan Name"
                          name="plan"
                          value={plan.plan}
                          onChange={(e) => handleValidityChange(index, e)}
                          size="lg"
                          type="text"
                        />
                      </MDBCol>
                      <MDBCol md="4">
                        <MDBInput
                          label="Months"
                          name="month"
                          value={plan.month}
                          onChange={(e) => handleValidityChange(index, e)}
                          size="lg"
                          type="text"
                        />
                      </MDBCol>
                      <MDBCol md="4">
                        <MDBInput
                          label="Price"
                          name="price"
                          value={plan.price}
                          onChange={(e) => handleValidityChange(index, e)}
                          size="lg"
                          type="text"
                        />
                      </MDBCol>
                    </MDBRow>

                    <MDBRow className="align-items-center pt-4 pb-3">
                      <MDBCol md="12">
                        <ReactQuill
                          value={plan.features.join('\n')}
                          onChange={(value) => handleFeaturesChange(index, value)}
                          modules={modules}
                          theme="snow"
                          placeholder="Enter features (one per line)"
                        />
                      </MDBCol>
                    </MDBRow>

                    {formData.validity.length > 1 && (
                      <MDBBtn
                        color="danger"
                        size="sm"
                        onClick={() => removeValidityPlan(index)}
                        className="mb-3"
                      >
                        Remove Plan
                      </MDBBtn>
                    )}
                    <hr className="mx-n2 m-0" />
                  </div>
                ))}

                <MDBBtn color="success" size="lg" onClick={addValidityPlan} className="my-4 mx-4">
                  Add Plan
                </MDBBtn>
                <MDBBtn className="my-4 mx-4" size="lg" type="submit">
                  Update Offer
                </MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </form>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}
