import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBFile,
  MDBInput,
  MDBRow,
} from 'mdb-react-ui-kit';
import { Container } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { Paths } from '../../../../app/baseApi/baseApi';
import axios from 'axios';
import ReactQuill from 'react-quill';
import { useState } from 'react';

export default function UploadOffersTab() {
  const [viewPlanQBBanner, setViewPlanQBBanner] = useState({
    bannerImage: null,
    validity: [
      {
        plan: '',
        month: '',
        price: '',
        features: [],
      },
    ],
  });
  const [isError, setIsError] = useState({});

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setViewPlanQBBanner({ ...viewPlanQBBanner, bannerImage: file });
    setIsError({ ...isError, bannerImage: '' });
  };

  const handleValidityChange = (index, e) => {
    const { name, value } = e.target;
    const newValidity = [...viewPlanQBBanner.validity];
    newValidity[index] = { ...newValidity[index], [name]: value };
    setViewPlanQBBanner({ ...viewPlanQBBanner, validity: newValidity });
    setIsError({ ...isError, [name]: '' });
  };

  const handleFeaturesChange = (index, value) => {
    const newValidity = [...viewPlanQBBanner.validity];
    newValidity[index].features = value.split('\n').filter((feature) => feature.trim() !== '');
    setViewPlanQBBanner({ ...viewPlanQBBanner, validity: newValidity });
  };

  const addValidityPlan = () => {
    setViewPlanQBBanner({
      ...viewPlanQBBanner,
      validity: [...viewPlanQBBanner.validity, { plan: '', month: '', price: '', features: [] }],
    });
  };

  const removeValidityPlan = (index) => {
    if (viewPlanQBBanner.validity.length > 1) {
      const newValidity = viewPlanQBBanner.validity.filter((_, i) => i !== index);
      setViewPlanQBBanner({ ...viewPlanQBBanner, validity: newValidity });
    }
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};
    if (!viewPlanQBBanner.bannerImage) {
      newErrors.bannerImage = 'Banner Image is required';
      isValid = false;
    }
    viewPlanQBBanner.validity.forEach((plan, index) => {
      if (!plan.plan.trim()) {
        // newErrors[`plan_${index}`] = 'Plan Name is required';
        isValid = false;
      }
    });

    setIsError(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formData = new FormData();
      formData.append('bannerImage', viewPlanQBBanner.bannerImage);
      formData.append('validity', JSON.stringify(viewPlanQBBanner.validity));
      try {
        const response = await Paths.EndpointsURL.GetOffersTabPrice;
        const record = await axios.post(response, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        window.location.reload();
        toast.success(record.data.message, { position: 'top-right' });
        setViewPlanQBBanner({
          bannerImage: null,
          validity: [
            {
              plan: '',
              month: '',
              price: '',
              features: [],
            },
          ],
        });
        setIsError({});
      } catch (error) {
        console.error('Error uploading data:', error);
        toast.error(error.response?.data?.message || 'An error occurred', {
          position: 'top-right',
        });
      }
    }
  };

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ header: 1 }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ direction: 'rtl' }],
    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ['link', 'image', 'video', 'formula', 'emoji'],
    ['undo', 'redo'],
    ['fullscreen'],
    ['clean'],
  ];

  const module = {
    toolbar: toolbarOptions,
  };

  return (
    <Container>
      <MDBContainer fluid>
        <MDBRow
          className="d-flex justify-content-center align-items-center"
          style={{ width: '900px' }}
        >
          <MDBCol lg="12" className="my-2">
            <h3 className="text-black mt-0 text-center">Offer Tab Price</h3>
            <form onSubmit={handleSubmit}>
              <MDBCard>
                <MDBCardBody className="px-4">
                  <MDBRow className="align-items-center pt-4 pb-3">
                    <MDBCol md="12">
                      <MDBFile
                        size="lg"
                        id="bannerImage"
                        name="bannerImage"
                        accept="image/*"
                        onChange={handleImageChange}
                      />
                    </MDBCol>
                  </MDBRow>
                  <hr className="mx-n2 m-0" />
                  {viewPlanQBBanner.validity.map((plan, index) => (
                    <div key={index}>
                      <MDBRow className="align-items-center pt-4 pb-3">
                        <MDBCol md="4">
                          <MDBInput
                            label="Plan Name"
                            name="plan"
                            value={plan.plan}
                            onChange={(e) => handleValidityChange(index, e)}
                            size="lg"
                            type="text"
                          />
                          {isError[`plan_${index}`] && (
                            <div className="text-danger">{isError[`plan_${index}`]}</div>
                          )}
                        </MDBCol>
                        <MDBCol md="4">
                          <MDBInput
                            label="Months"
                            name="month"
                            value={plan.month}
                            onChange={(e) => handleValidityChange(index, e)}
                            size="lg"
                            type="text"
                          />
                        </MDBCol>
                        <MDBCol md="4">
                          <MDBInput
                            label="Price"
                            name="price"
                            value={plan.price}
                            onChange={(e) => handleValidityChange(index, e)}
                            size="lg"
                            type="text"
                          />
                        </MDBCol>
                      </MDBRow>

                      <MDBRow className="align-items-center pt-4 pb-3">
                        <MDBCol md="12">
                          <ReactQuill
                            placeholder="Enter features (one per line)"
                            value={plan.features.join('\n')}
                            onChange={(value) => handleFeaturesChange(index, value)}
                            modules={module}
                            theme="snow"
                          />
                        </MDBCol>
                      </MDBRow>
                      {viewPlanQBBanner.validity.length > 1 && (
                        <MDBBtn
                          color="danger"
                          size="sm"
                          onClick={() => removeValidityPlan(index)}
                          className="mb-3"
                        >
                          Remove Plan
                        </MDBBtn>
                      )}
                      <hr className="mx-n2 m-0" />
                    </div>
                  ))}
                  <MDBBtn color="success" size="lg" onClick={addValidityPlan} className="my-4 mx-4">
                    Add Plan
                  </MDBBtn>
                  <MDBBtn className="my-4 mx-4" size="lg" type="submit">
                    Submit
                  </MDBBtn>
                </MDBCardBody>
              </MDBCard>
            </form>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </Container>
  );
}
