import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import { styled, TablePagination } from '@mui/material';
import React, { useState, useEffect } from 'react';
import PageHeader from 'app/PageHeader';
import Link from '@mui/material/Link';
import DeleteIcon from '@mui/icons-material/Delete';
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone';
import Button from 'react-bootstrap/Button';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Paths } from '../../../baseApi/baseApi';
import Swal from 'sweetalert2';
import UploadOffersTabModel from './UploadOffersTabModel';
import EditOffersTabModel from './EditOffersTabModel';

const Container = styled('div')(({ theme }) => ({
  margin: '50px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '50px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
  },
}));

const OffersTab = () => {
  const [homeExams, setHomeExams] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [isError, setIsError] = useState('');
  const [isDelete, setIsDelete] = useState('');
  const [isExpanded, setIsExpanded] = useState([]);
  const [selectedBanner, setSelectedBanner] = useState(null);

  const handleEdit = (bannerData) => {
    setSelectedBanner(bannerData);
    console.log(bannerData, 'bannerData...');
    setModalShow1(true);
  };

  const displayExams = async () => {
    try {
      const response = await Paths.EndpointsURL.GetOffersTabPrice;
      axios({
        url: response,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }).then((record) => {
        setHomeExams(record.data.data.reverse());
      });
    } catch (error) {
      setIsError(error.msg);
      console.log(error.msg);
    }
  };

  const handleDelete = async (id) => {
    const confirmed = await confirmDelete();
    displayExams();
    if (confirmed) {
      try {
        const deleteResp = Paths.EndpointsURL.GetOffersTabPrice + `/${id}`;
        const res = await axios.delete(deleteResp);
        if (res.data.success && res.data.statusCode === 200) {
          handleDeleteResponse(res);
          window.location.reload();
        } else {
          toast.error(res.data.message || 'Error deleting coupon', { position: 'top-right' });
        }
      } catch (error) {
        handleError(error);
      }
    } else {
    }
  };

  const confirmDelete = async () => {
    const { isConfirmed } = await Swal.fire({
      title: 'Are You Sure?',
      text: 'Are you sure that you want to delete this coupon?',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonColor: '#3085d6',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Confirm',
    });
    return isConfirmed;
  };

  const handleDeleteResponse = (res) => {
    toast.success('Record deleted successfully', { position: 'top-right' });
    window.location.reload();
    setIsDelete(true);
    // console.log('Coupon deleted:', res.data);
  };

  const handleError = (error) => {
    console.error('Error deleting coupon:', error.message);
    // toast.error('Error deleting coupon', { position: 'top-right' });
  };

  const handleToggleClick = (index) => {
    setIsExpanded((prevExpandedItems) => {
      const newExpandedItems = [...prevExpandedItems];
      newExpandedItems[index] = !newExpandedItems[index];
      return newExpandedItems;
    });
  };

  useEffect(() => {
    displayExams();
  }, []);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Container>
      <PageHeader
        title="Offers Tab"
        subTitle="Know More Offers"
        icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
      />
      <Button variant="primary" onClick={() => setModalShow(true)}>
        Add New
      </Button>
      <UploadOffersTabModel show={modalShow} onHide={() => setModalShow(false)} />
      <EditOffersTabModel
        show={modalShow1}
        onHide={() => setModalShow1(false)}
        editPlanQBBenefitsData={selectedBanner}
      />

      <MDBTable align="middle">
        <MDBTableHead>
          <tr>
            <th scope="col">Plan Name</th>
            <th scope="col">Image</th>
            <th scope="col">Validity</th>
            <th scope="col">Price</th>
            <th scope="col">Features</th>
            <th scope="col">Actions</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {homeExams?.map((result, index) => (
            <tr key={index}>
              <td>
                {result.validity.map((validityItem, validityIndex) => (
                  <p key={validityIndex} className="fw-normal mb-1">
                    {validityItem.plan}
                  </p>
                ))}
              </td>
              <td>
                <img
                  src={result.bannerImage}
                  alt={result.bannerImage}
                  style={{ width: '45px', height: '45px' }}
                  className="rounded-circle"
                />
              </td>
              <td>
                {result.validity.map((validityItem, validityIndex) => (
                  <p key={validityIndex} className="fw-normal mb-1">
                    {validityItem.month}
                  </p>
                ))}
              </td>
              <td>
                {result.validity.map((validityItem, validityIndex) => (
                  <p key={validityIndex} className="fw-normal mb-1">
                    {validityItem.price}
                  </p>
                ))}
              </td>
              <td>
                {result.validity.map((validityItem, validityIndex) => (
                  <div
                    key={validityIndex}
                    className="scroll_paragraph_2"
                    dangerouslySetInnerHTML={{
                      __html: isExpanded[index * result.validity.length + validityIndex]
                        ? validityItem.features?.join('<br>')
                        : validityItem.features
                        ? `${validityItem.features[0].substring(0, 20)}...`
                        : '',
                    }}
                  />
                ))}
              </td>
              <td style={{ padding: '0px', cursor: 'pointer' }}>
                <Link onClick={() => handleEdit(result)}>
                  <EditIcon />
                </Link>
                <Link onClick={() => handleDelete(result._id)} style={{ color: 'red' }}>
                  <DeleteIcon />
                </Link>
              </td>
            </tr>
          ))}
        </MDBTableBody>
      </MDBTable>
      <TablePagination
        sx={{ px: 2 }}
        page={page}
        component="div"
        rowsPerPage={rowsPerPage}
        count={homeExams.length}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[10, 20, 30]}
        onRowsPerPageChange={handleChangeRowsPerPage}
        nextIconButtonProps={{ 'aria-label': 'Next Page' }}
        backIconButtonProps={{ 'aria-label': 'Previous Page' }}
      />
    </Container>
  );
};

export default OffersTab;
